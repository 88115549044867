<fieldset ngModelGroup="etatLieuxContent" #etatLieuxContent="ngModelGroup" [disabled]="!(TypeDroit.ETAT_LIEUX | right:'creation')">
	<div class="card" [ngClass]="{ 'focus m-b-0': source == 'ETAT_LIEUX' }">
		<div class="card-body" [ngClass]="{ 'card-padding': source == 'ETAT_LIEUX' }">
			<fieldset [disabled]="etatLieux.statut == 'TERMINE'">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': etatLieuxContent.control?.controls['vehicule']?.invalid }" *ngIf="source != 'VEHICULE'">
							<label translate>vehicule.etatLieux.vehicule</label>
							<div>
								<ng-container *ngIf="!etatLieux.vehicule">
									<autocomplete name="vehicule" type="vehicule" [filter]="{ typeVehicule: 'VEHICULE_FONCTION_SERVICE' }" #vehicule="ngModel" [(ngModel)]="etatLieux.vehicule" required></autocomplete>
								</ng-container>
								<ng-container *ngIf="etatLieux.vehicule">
									<mat-chip-listbox>
										<mat-chip [ngClass]="{ 'clickable': (TypeDroit.ADMIN_VEHICULE | right:'consultation') }" (click)="rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'consultation') && goToVehicule()" (removed)="etatLieux.vehicule = null">
											{{ etatLieux.vehicule | display:'vehicule' }}
											<button matChipRemove *ngIf="(TypeDroit.ETAT_LIEUX | right:'creation') && etatLieux.reservation === null">
												<mat-icon>cancel</mat-icon>
											</button>
										</mat-chip>
									</mat-chip-listbox>
								</ng-container>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': datePlanification.invalid }">
							<label translate>vehicule.etatLieux.datePlanification</label>
							<div class="half">
								<input type="text" class="form-control" name="datePlanification" #datePlanification="ngModel" [(ngModel)]="etatLieux.datePlanification" datePicker format="datetime" [required]="etatLieux.dateRealisation == null" [minDate]="datePlanificationMin" (ngModelChange)="etatLieux.dateRealisation = etatLieux.dateRealisation || null" [disabled]="etatLieux.reservation?.idReservation > 0"/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': dateRealisation.invalid }">
							<label translate>vehicule.etatLieux.dateRealisation</label>
							<div class="half">
								<input type="text" class="form-control" name="dateRealisation" #dateRealisation="ngModel" [(ngModel)]="etatLieux.dateRealisation" datePicker format="datetime" [required]="etatLieux.datePlanification == null" maxDate="today"/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': compteur.invalid }" *ngIf="etatLieux.dateRealisation && (etatLieux.statut != 'TERMINE' || etatLieux.compteur)">
							<label translate>vehicule.etatLieux.compteur</label>
							<div class="half">
								<div class="input-group">
									<input type="text" name="compteur" class="form-control" #compteur="ngModel" [(ngModel)]="etatLieux.compteur" nNumber required/>
									<div class="input-group-addon no-border" *ngIf="etatLieux.vehicule?.unite || user?.unite?.libelleCourt">
										<span>{{ etatLieux.vehicule?.unite?.libelleCourt || user?.unite?.libelleCourt }}</span>
									</div>
									<div class="input-group-addon">
										<icon name="replay_30" class="icon-hc-2x clickable" *ngIf="etatLieux.vehicule && user?.type != 'VIRTUAL'" (click)="vehiculeService.showListeCompteurs(etatLieux.vehicule)"></icon>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group" *ngIf="etatLieux.dateRealisation && etatLieux.statut == 'TERMINE' && !etatLieux.compteur">
							<label translate>vehicule.etatLieux.compteur</label>
							<div>
								<span *ngIf="etatLieux.statut == 'TERMINE' && !etatLieux.compteur">
									<span translate>etatLieux.nonCommuniquee</span>
								</span>
							</div>
						</div>
						<div class="form-group" *ngIf="source != 'CONDUCTEUR' && etatLieux.extension">
							<label translate>vehicule.etatLieux.collaborateurAffecte</label>
							<div>
								<span *ngIf="etatLieux.extension?.userAffecte">{{ etatLieux.extension.userAffecte | display:'user' }}</span>
								<span *ngIf="!etatLieux?.extension?.userAffecte" translate>common.nonDefini</span>
							</div>
						</div>
						<div class="form-group" *ngIf="etatLieux.statut == 'TERMINE' && etatLieux.user != null">
							<label translate>vehicule.etatLieux.realisePar</label>
							<div>
								<span>{{ etatLieux.user | display:'user' }}</span>
							</div>
						</div>
						<div class="form-group" *ngIf="etatLieux.planifie === false || !!etatLieux.dateRealisation">
							<label translate>vehicule.etatLieux.attachment.item</label>
							<div>
								<a [routerLink]="[]" (click)="showListeParts();">
									<span translate [translateParams]="{ nb: nbLinks }">vehicule.etatLieux.attachment.description</span>
									<icon name="warning" class="m-l-5 icon-hc-2x" *ngIf="!hasAllParts(etatLieux)" [tooltip]="'vehicule.etatLieux.attachment.incomplete' | translate"></icon>
								</a>
							</div>
						</div>
					</div>
					<div class="col-md-6" *ngIf="etatLieux.statut">
						<div class="form-group">
							<label translate>vehicule.etatLieux.statut.item</label>
							<div>
								<span [translate]="'vehicule.etatLieux.statut.'+etatLieux.statut"></span>
							</div>
						</div>
						<div class="form-group" *ngIf="etatLieux.reservation">
							<label translate>vehicule.etatLieux.reservation</label>
							<div>
								<mat-chip-listbox>
									<mat-chip [class.clickable]="TypeDroit.VEHICULE_RESERVATION | right:'consultation'" (click)="user?.type != 'VIRTUAL' && goToReservation()">
										<span><span translate>vehicule.etatLieux.reservation</span>&#160;(<span>{{ etatLieux.reservation.idReservation }}</span>)</span>
									</mat-chip>
								</mat-chip-listbox>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
			<div class="form-group">
				<label translate>vehicule.etatLieux.remarque</label>
				<div>
					<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="etatLieux.remarque" rows="3"></textarea>
				</div>
			</div>
		</div>
	</div>
</fieldset>