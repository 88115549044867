import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { DashboardService } from 'src/app/components/dashboard/dashboard.service';
import { EtatLieuxListItemComponent } from './etat-lieux-list-item.component';
import { mapEntites } from './etat-lieux.entites';
import { EtatLieuxService } from './etat-lieux.service';
import { ConnecteurService } from 'src/app/share/components/connecteur/connecteur.service';


@Component({
	templateUrl: './etat-lieux-list.component.html'
})
export class EtatLieuxListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,EtatLieuxListItemComponent>;

	/** Présence d'un dashboard **/
	hasDashboard: boolean = false;

	/** Présence d'imports **/
	hasImports: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private layoutService: LayoutService,private etatLieuxService: EtatLieuxService,public dashboardService: DashboardService,public extractionService: ExtractionService,public connecteurService: ConnecteurService) { }

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,EtatLieuxListItemComponent>({
			uri: `/controller/EtatLieux/filtreEtatsLieux`,
			title: this.translateService.instant('vehicule.etatLieux.liste.title'),
			component: EtatLieuxListItemComponent,
			listeFilters: [{
				clef: 'vehicule',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'vehicule',
					filter: 'VEHICULE_FONCTION_SERVICE'
				},
				isDefault: true
			},{
				clef: 'extension.userAffecte',
				title: this.translateService.instant('search.user'),
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'user'
				}
			},{
				clef: '-datePlanification',
				type: TypeFilter.DATE
			},{
				clef: '-dateRealisation',
				type: TypeFilter.DATE
			},{
				clef: 'isPlanifie',
				type: TypeFilter.BOOLEAN
			},{
				clef: 'statut',
				type: TypeFilter.LONG,
				listeValues: this.etatLieuxService.getListeStatuts()
			}],
			defaultOrder: 'statut,-dateRealisation,-datePlanification,vehicule.reference',
			getKeyFieldName: () => 'idEtatLieux',
			doMainAction: this.rightService.hasRight(null,'creation') && (() => this.layoutService.goToByUrl('/EtatLieux/etatLieux/0'))
		});

		//Vérification de la présence d'un dashboard
		this.dashboardService.hasDashboard().subscribe({
			next: (hasDashboard: boolean) => {
				//Définition de la présence d'un dashboard
				this.hasDashboard = hasDashboard;
			}
		});

		//Vérification de la présence d'imports
		this.rightService.hasRight(null,'creation') && this.connecteurService.isImportAvailable().pipe(map(result => result?.isAvailable)).subscribe({
			next: (isImportAvailable: boolean) => {
				//Définition de la présence d'imports
				this.hasImports = isImportAvailable;
			}
		});
	}
}