<div class="modal-header">
	<h4 class="modal-title">{{ options.title }}</h4>
</div>
<div class="modal-body">
	<ol class="breadcrumb p-l-0 b-0 m-b-0" *ngIf="listeOpenedLevels.length > 0 && options.listeSearchLevels.length > 1">
		<li *ngFor="let level of listeOpenedLevels; let index = index; let last = last">
			<a *ngIf="!last" class="clickable" [routerLink]="[]" (click)="returnToLevel(index)"><span>{{ level.libelleDynamique || level.libelle }}</span></a>
			<span *ngIf="last">{{ level.libelle }}</span>
		</li>
	</ol>

	<div class="row" *ngIf="options.isEmbeddedDashboardExtraction">
		<div class="col-md-6">
			<div class="form-group">
				<label>
					<span translate>extraction.selection.filtreObjet.item</span>
					<icon name="info_outline" class="m-l-5" [tooltip]="'extraction.selection.filtreObjet.description' | translate"></icon>
				</label>
				<div>
					<mat-checkbox name="isKeepFilteringObject" [(ngModel)]="isKeepFilteringObject"><span translate>extraction.selection.filtreObjet.info</span></mat-checkbox>
				</div>
			</div>
		</div>
	</div>

	<ng-container *ngFor="let liste of listeLevelLists; trackBy: trackByPosition; let index = index">
		<ng-container *ngIf="index == currentLevelIndex">
			<list-view [liste]="liste"></list-view>

			<ul class="list-group" *ngIf="!options.listeSearchLevels[currentLevelIndex].isListView && liste?.data?.content?.length > 0">
				<li class="list-group-item clickable" *ngFor="let groupe of liste?.data?.content; trackBy: trackByPosition" (click)="openLevel(groupe)">
					<span *ngIf="!options.listeSearchLevels[currentLevelIndex].isSelectableGroupe">
						<a [routerLink]="[]"><span>{{ groupe.libelleGroupe || options.listeSearchLevels[currentLevelIndex].emptyLibelle }}</span>&#160;(<span>{{ groupe.nbItems }}</span>)</a>
					</span>
					<ng-container *ngIf="options.listeSearchLevels[currentLevelIndex].isSelectableGroupe">
						<mat-checkbox *ngIf="!options.isSingleResult" [(ngModel)]="groupe.isChecked" [indeterminate]="groupe.selected == 'SOME'" (change)="onSelectGroupe(groupe)" (click)="$event.stopPropagation()"></mat-checkbox>
						<span>
							<a [routerLink]="[]"><span>{{ options.listeSearchLevels[currentLevelIndex].getTitle(groupe) || options.listeSearchLevels[currentLevelIndex].emptyLibelle }}</span>&#160;(<span>{{ groupe.nbItems }}</span>)</a>
						</span>
					</ng-container>
					<div><icon name="chevron_right" class="icon-hc-2x"></icon></div>
				</li>
			</ul>

			<div class="listview lv-bordered" *ngIf="options.listeSearchLevels[currentLevelIndex].isListView && liste?.data?.content?.length > 0">
				<div class="lv-body">
					<div class="lv-item media" *ngFor="let item of liste?.data?.content; trackBy: trackByPosition">
						<div class="pull-left lv-avatar" *ngIf="!options.listeSearchLevels[currentLevelIndex].getAvatarIcon">{{ options.listeSearchLevels[currentLevelIndex].getAvatar(item) }}</div>
						<div class="pull-left lv-avatar" *ngIf="options.listeSearchLevels[currentLevelIndex].getAvatarIcon">
							<icon [name]="options.listeSearchLevels[currentLevelIndex].getAvatarIcon(item)" [type]="options.listeSearchLevels[currentLevelIndex].getAvatarIconType(item)"></icon>
						</div>
						<div class="media-body">
							<div class="lv-title">
								<a [routerLink]="[]" (click)="selectItem(item);"><span>{{ options.listeSearchLevels[currentLevelIndex].getTitle(item) }}</span></a>
							</div>
						</div>
						<span class="pull-right checkbox m-t-0 m-b-0" *ngIf="!options.isSingleResult">
							<input type="checkbox" [(ngModel)]="item.selected" (change)="doSelectItem(item)"/>
							<i class="input-helper"></i>
						</span>
						<ng-container *ngIf="options.listeSearchLevels[this.currentLevelIndex].getListeItemAttributes?.(item) as listeAttributes">
							<ul class="lv-attrs" *ngIf="listeAttributes?.length">
								<li *ngFor="let attribute of listeAttributes; trackBy: trackByPosition"><span>{{ attribute.key }}</span>&#160;:&#160;<span>{{ attribute.value }}</span></li>
							</ul>
						</ng-container>
						<div class="lv-actions actions dropdown" *ngIf="options.listeSearchLevels[this.currentLevelIndex].getItemAction?.(item) as itemAction">
							<a [routerLink]="[]" (click)="itemAction.doAction(item);"><icon [name]="itemAction.icon" [type]="itemAction.iconType"></icon></a>
						</div>
					</div>
				</div>
			</div>

			<div class="text-center p-10" *ngIf="!liste.data || !liste.data.content">
				<h5><please-wait [message]="'liste.loading' | translate" size="hc-md"></please-wait></h5>
			</div>
			<div class="text-center p-10" *ngIf="liste.data?.content?.length == 0">
				<h5><span translate>liste.empty</span></h5>
			</div>
		</ng-container>
	</ng-container>
</div>
<div class="modal-footer">
	<button mat-button (click)="options.isShowFilter ? openAdvancedSearch() : close()">
		<span translate>actions.fermer</span>
	</button>
	<button mat-button color="primary" [class.mat-raised-button]="action.isPrimary" [class.mat-stroked-button]="action.isSecondary" *ngFor="let action of listeOpenedLevels[currentLevelIndex].listeActions | filterBy:isActionVisible" [disabled]="!action.isValid(options,listeSelectionsItems)" (click)="action.onPress(options,listeCumulatedFilters);">
		<span>{{ action.libelle }}</span>
	</button>
	<button mat-raised-button color="primary" *ngIf="!options.isSingleResult" (click)="validateSelection();" [disabled]="nbSelectedItems == 0">
		<span translate>actions.integrer</span>&#160;<span>({{ nbSelectedItems }})</span>
	</button>
</div>