import { Component,Input,OnChanges,OnInit,SimpleChanges,ViewChild } from '@angular/core';
import { first } from 'rxjs';
import { Store } from '@ngrx/store';

import { Element } from 'src/app/domain/common/complex-page/element';
import { Options } from 'src/app/domain/common/complex-page/options';
import { ComplexPageElementsComponent } from './complex-page-elements.component';
import { ExtendedRouteData } from 'src/app/domain/common/route/extended-route';
import { AppState } from 'src/app/domain/appstate';
import { DashboardService } from 'src/app/components/dashboard/dashboard.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { TypeDashboard } from 'src/app/domain/dashboard/dashboard';

@Component({
	selector: 'complex-page',
	templateUrl: './complex-page.component.html'
})
export class ComplexPageComponent implements OnInit,OnChanges {
	/** Options d'affichage **/
	@Input() options: Options;

	/** Données **/
	@Input() data: any;

	/** Composant de gestion des éléments **/
	@ViewChild('complexPageElements') complexPageElements: ComplexPageElementsComponent;

	/** Indicateur de présence d'un dashboard embarqué **/
	public isEmbeddedDashboard: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,public dashboardService: DashboardService,public layoutService: LayoutService) {}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération de la présence d'un dashboard embarqué
		this.store.select<ExtendedRouteData>(s => s.layout?.activatedRouteData).pipe(first()).subscribe(data => this.isEmbeddedDashboard = data?.isEmbeddedDashboard);
	}

	/**
	 * Détection d'une modification de la donnée
	 */
	ngOnChanges(changes: SimpleChanges) {
		//Vérification de l'initialisation du composant
		if (changes.data?.firstChange)
			//Mise à jour de l'élément sélectionné par défaut
			setTimeout(() => this.onActionSelected(this.options.listeElements?.filter(e => !e.isVisible || e.isVisible())?.filter(e => !e.isElementVisible || e.isElementVisible())?.[0]));
	}

	/**
	 * Sélection d'un élément dans les actions rapides
	 */
	onActionSelected(element: Element) {
		//Définition de l'élément sélectionné dans le composant de gestion des éléments
		element && this.complexPageElements?.setSelectedElement(element);
	}

	/**
	 * Définition de l'élément sélectionné (par son type)
	 */
	public setSelectedElementByType(type: string,forceRefresh: boolean = false) {
		let element: Element;

		//Vérification du besoin de charger la page
		if (forceRefresh || this.complexPageElements?.selectedElement?.type != type) {
			//Recherche de l'élément
			element = this.options.listeElements.find(e => e.type == type);

			//Sélection de l'élément
			this.onActionSelected(element);
		}
	}

	/**
	 * Vérification de la mise en plein écran
	 */
	public isFullScreen(): boolean {
		//Vérification de la mise en plein écran
		return typeof this.options?.isFullScreen == 'boolean' && this.options?.isFullScreen || typeof this.options?.isFullScreen == 'function' && this.options?.isFullScreen?.();
	}

	/**
 	 * Affichage des extractions
	 */
	public showExtraction(): void {
		let typeEmbeddedDashboard: TypeDashboard;
		let idObjet: number;

		//Récupération du type de dashboard embarqué
		typeEmbeddedDashboard = this.layoutService.getExtendedRouteData().typeDashboard;

		//Récupération de l'identifiant de l'objet
		idObjet = this.layoutService.getRouteComponentInstance().getIdObject();

		//Affichage des extractions
		this.dashboardService.showExtraction(typeEmbeddedDashboard,idObjet);
	}
}
