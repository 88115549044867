import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { ImportTemplateService } from './import-template.service';
import { ImportTemplateComponent } from './import-template.component';
import { SelectPickerModule } from 'src/app/share/components/select-picker/select-picker.module';
import { ImportTemplateExpediteursComponent } from './import-template-expediteurs.component';
import { RightModule } from 'src/app/share/pipe/right/right.module';
import { PluralTranslateModule } from 'src/app/share/pipe/plural-translate/plural-translate.module';
import { IconModule } from 'src/app/share/components/icon/icon.module';
import { ImportTemplateEmailComponent } from './import-template-email.component';

@NgModule({
	imports: [CoreComponentsModule,SelectPickerModule,RightModule,PluralTranslateModule,IconModule],
	declarations: [ImportTemplateComponent,ImportTemplateExpediteursComponent,ImportTemplateEmailComponent],
	providers: [ImportTemplateService]
})
export class ImportTemplateModule {}