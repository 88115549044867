<ul [ngClass]="{ 'hidden': !listeItems.length && !isAlwaysShown,'global': isGlobal }" class="mfb-component--br mfb-zoomin" [attr.data-mfb-state]="isOpened ? 'open' : 'closed'" [attr.active-icon]="defaultIcon" [attr.resting-icon]="defaultIcon">
	<li class="mfb-component__wrap">
		<a class="mfb-component__button--main" [ngClass]="{ 'global': isGlobal }" (click)="isOpened = !isOpened; onClick?.()" matRipple>
			<icon class="mfb-component__main-icon--resting" [ngClass]="{ 'no-transform': isGlobal }" [name]="defaultIcon" [type]="iconType"></icon>
			<icon class="mfb-component__main-icon--active" [ngClass]="{ 'no-transform': isGlobal }" [name]="defaultIcon" [type]="iconType"></icon>
		</a>

		<ul class="mfb-component__list" *ngIf="listeItems?.length">
			<ng-container *ngFor="let item of listeItems">
				<ng-template [ngTemplateOutlet]="item.template"></ng-template>
			</ng-container>
		</ul>
	</li>
</ul>