import { Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: './import-template-email.component.html'
})
export class ImportTemplateEmailComponent  {
	/** Email de réception des fichiers d'import **/
	@Input() email: string;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<ImportTemplateEmailComponent>) {}
}