import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable,of,Subject,throwError } from 'rxjs';
import { catchError,filter,map,shareReplay,take,tap } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { Filter,ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { ActionMasse } from 'src/app/domain/common/list-view/action';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { TypeFrequence } from 'src/app/domain/scheduler/scheduler';
import { TypeTenant } from 'src/app/domain/security/right';
import { Session } from 'src/app/domain/security/session';
import { User } from 'src/app/domain/user/user';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { AutocompleteService } from 'src/app/share/components/autocomplete/autocomplete.service';
import { EntiteService } from 'src/app/share/components/entite/entite.service';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { SchedulerService } from 'src/app/share/components/scheduler/scheduler.service';
import { SuccessiveSearchService } from 'src/app/share/components/successive-search/successive-search.service';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ExtractionService {
	/** Liste des types de diffusion **/
	private readonly listeTypesDiffusion: Array<string> = ['TOUS','REVENDEUR','CLIENT','ROOT'];

	/** Liste des types de format de sortie **/
	private readonly listeTypesFormat: Array<string> = ['XLSX','CSV'];

	/** Cache de présence des extractions pour une entité **/
	private mapExtractionsForEntite: { [entite: string]: boolean } = {};

	/** Utilisateur connecté **/
	private user: User;

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private bsModalService: BsModalService,private messagingService: MessagingService,private store: Store<AppState>,private entiteService: EntiteService,private schedulerService: SchedulerService,private toastrService: ToastrService
			,private toasterService: ToastrService,private translateService: TranslateService,private attachmentService: AttachmentService,private autocompleteService: AutocompleteService,private successiveSearchService: SuccessiveSearchService) {
		//Sélection de la session
		this.store.select<Session>(s => s.session).subscribe(session => {
			//Définition de l'utilisateur
			this.user = session.user;
		});
	}

	/**
	 * Suppression de l'extraction
	 */
	public deleteExtraction(typeReferentiel: TypeReferentiel,extraction: any): Observable<Result> {
		//Suppression de l'extraction
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Extraction/deleteExtraction/${typeReferentiel}/${extraction.idExtraction}`);
	}

	/**
	 * Duplication de l'extraction
	 */
	public duplicateExtraction(extraction: any): Observable<Result> {
		//Duplication de l'extraction
		return this.http.post<Result>(`${environment.baseUrl}/controller/Extraction/duplicateExtraction/${extraction.idExtraction}`,null);
	}

	/**
	 * Vérification de l'existance d'extractions pour une entité
	 */
	isExtractionAvailableFor(entiteOrListeEntites: string | Array<string>): Observable<boolean> {
		let listeEntites: Array<string>;
		let keyEntite: string;

		//Définition de la liste des entités
		listeEntites = typeof entiteOrListeEntites == 'string' ? [entiteOrListeEntites] : entiteOrListeEntites;

		//Définition de la clé
		keyEntite = listeEntites.join(',');

		//Vérification de l'absence en cache et de la présence de l'entité dans la liste
		if (this.mapExtractionsForEntite[keyEntite] === undefined) {
			//Vérification des extractions pour la liste des entités sélectionnées
			return this.http.post<Result>(`${environment.baseUrl}/controller/Extraction/isExtractionAvailableFor`,{
				listeFilter: [{
					clef: 'entity',
					listeObjects: listeEntites,
					typeComparaison: TypeComparaison.IN,
					type: TypeFilter.STRING
				}]
			}).pipe(
				take(1),
				map(result => result.data.isAvailable),
				tap(isAvailable => this.mapExtractionsForEntite[keyEntite] = isAvailable)
			);
		} else
			//Retour du résultat
			return of(this.mapExtractionsForEntite[keyEntite]);
	}

	/**
	 * Récupération de la liste des extractions pour une entité
	 */
	getListeExtractionsForEntite(entite: string,entiteOrListeEntites: string | Array<string>,isForMasseAction: boolean,isForEmbeddedDashboard: boolean): Observable<any> {
		let subject: Subject<any> = new Subject<any>();

		//Affichage de la recherche successive
		this.successiveSearchService.showSuccessiveSearch({
			title: this.translateService.instant('extraction.selection.titre'),
			typeSearchLevel: 'ENTITE',
			search: {
				listeFilter: []
			},
			isShowFilter: false,
			groupeKey: null,
			idGroupeKey: 'entite',
			idItemKey: 'idExtraction',
			isSingleResult: true,
			isEmbeddedDashboardExtraction: isForEmbeddedDashboard,
			listeSearchLevels: [entiteOrListeEntites == null && entite == null && {
				type: 'ENTITE',
				libelle: this.translateService.instant('extraction.selection.entite'),
				getLibelleDynamique: (groupe: any) => this.entiteService.translateEntityCode(groupe.entity.split('.').pop()),
				uri: () => `/controller/Extraction/filtreItemsByEntite`,
				defaultSearch: 'entity',
				getListeFilters: () => [{
					clef: 'entity',
					isDefault: true
				}],
				isListView: false,
				getTitle: (item) => this.entiteService.translateEntityCode(item.entity.split('.').pop()),
				getAssociatedFilter: (value,groupe) => {
					//Retour du filtre pour l'entité
					return {
						clef: 'entity',
						valeur: groupe.entity,
						typeComparaison: TypeComparaison.EQUAL,
						type: TypeFilter.STRING
					}
				},
				getListeFiltersExtended(options) {
					//Retour des filtres
					return options?.search?.listeFilter || [];
				}
			},{
				type: 'ITEM',
				libelle: this.translateService.instant('extraction.selection.item'),
				uri: () => `/controller/Extraction/${isForMasseAction ? 'filtreExtractionsFor' : 'filtreItems'}${entite != null ? '?entite=' + entite : ''}`,
				getListeFilters: () => [{
					clef: 'libelle',
					isDefault: true
				},{
					clef: 'reference',
					isDefault: true
				}],
				defaultOrder: 'libelle,reference',
				isListView: true,
				getTitle: (item: any) => item.libelle + ' (' + item.reference + ')',
				getAvatar: (item) => item.reference.substring(0,3).toUpperCase(),
				getListeItemAttributes: (item: any) => [{
					key: this.translateService.instant('extraction.selection.entite'),
					value: this.entiteService.translateEntityCode(item.entity.split('.').pop())
				},!isForMasseAction && !isForEmbeddedDashboard && {
					key: this.translateService.instant('extraction.selection.notification'),
					value: this.translateService.instant(`common.${item.userScheduler?.scheduler?.type && item.userScheduler?.scheduler?.type != TypeFrequence.NON_DEFINI ? 'oui' : 'non'}`)
				},!isForMasseAction && !isForEmbeddedDashboard && item.userScheduler?.scheduler?.type && item.userScheduler?.scheduler?.type != TypeFrequence.NON_DEFINI && {
					key: this.translateService.instant('extraction.selection.frequence'),
					value: this.translateService.instant(`scheduler.frequence.${item.userScheduler.scheduler.type}`)
				}].filter(f => !!f),
				getListeFiltersExtended: (options,listeCumulatedFilters) => {
					//Retour des filtres
					return (listeCumulatedFilters || []).concat(entiteOrListeEntites && [{
						clef: 'entity',
						listeObjects: typeof entiteOrListeEntites == 'string' ? [entiteOrListeEntites] : entiteOrListeEntites,
						typeComparaison: TypeComparaison.IN,
						type: TypeFilter.STRING
					}] || []);
				},
				getItemAction: (item: any) => !isForMasseAction && !isForEmbeddedDashboard && item.tenant?.type == TypeTenant.CLIENT && ({
					icon: 'email',
					doAction: (item: any) => {
						//Affichage de l'ordonnancement
						this.schedulerService.showScheduler(item.userScheduler?.scheduler,true).subscribe({
							next: scheduler => {
								let userScheduler: any;

								//Vérification du scheduler
								if (scheduler !== undefined) {
									//Récupération du scheduler de l'utilisateur (ou création si nécessaire)
									userScheduler = item.userScheduler && cloneDeep(item.userScheduler) || {};

									//Définition du scheduler choisi
									userScheduler.scheduler = scheduler;

									//Définition de l'entité pour le scheduler
									userScheduler.entityObject = item;
									userScheduler.entityObject._type = 'com.notilus.data.extraction.Extraction';

									//Enregistrement du scheduler de l'utilisateur
									this.saveUserScheduler(userScheduler).subscribe({
										next: (result: Result) => {
											//Vérification du code d'erreur
											if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
												//Message d'information
												this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

												//Définition de l'ordonnancement
												item.userScheduler = result.data.userScheduler;
											} else {
												//Message d'erreur
												this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
											}
										}
									});
								}
							}
						});
					}
				})
			}].filter(l => !!l)
		}).subscribe({
			next: result => {
				//Vérification du résultat
				if (result) {
					//Vérification du résultat obtenu
					if (result.listeSelectionsItems?.length) {
						//Retour de la sélection
						subject.next({
							extraction: result.listeSelectionsItems[0],
							isKeepFilteringObject: result.isKeepFilteringObject
						});

						//Fin de traitement
						subject.complete();
					} else if (result.isDismissed) {
						//Aucune sélection
						subject.next(null);
					}
				} else {
					//Emission d'une erreur
					subject.error(null);

					//Fin de traitement
					subject.complete();
				}
			},
			error: (erreur) => {
				//Transmission de l'erreur
				subject.error(erreur);

				//Fin de traitement
				subject.complete();
			}
		});

		return subject;
	}

	/**
	 * Déclenchement d'une extraction
	 */
	executeExtraction(extraction: any,options: { messagingOptions?: MessagingOptions,dashboardChart?: any,searchSpec?: any,ruleOverride?: any,baseFilename?: string } = {}): Observable<any> {
		let subject: Subject<any> = new Subject<any>();
		let messaging$: MessagingObservables;
		let extractionDate: string;

		//Récupération de la date pour le nom de fichier
		extractionDate = moment().format('DDMMYYYY_HHmmss');

		//Récupération de la liste des entités
		this.getListeEntites().pipe(take(1)).subscribe({
			next: listeEntites => {
				//Vérification de la présence de l'entité
				if (listeEntites.some(entite => entite.code == extraction.entity)) {
					//Démarrage de l'extraction par websocket
					messaging$ = this.messagingService.init({
						...options.messagingOptions,
						entryPoint: 'controller/Extraction/executeExtraction/'+extraction.idExtraction,
						outputPoint: '/messaging/doExtraction/status/',
						params: {
							dashboardChart: options.dashboardChart || null,
							searchSpec: options.searchSpec || null,
							ruleOverride: options.ruleOverride || null
						},
						progressConfig: {
							libelle: options.dashboardChart?.chart?.libelle || extraction.libelle,
							icone: 'download'
						}
					}).onFinish({
						next: ({ idSession }) => {
							//Fermeture des souscriptions
							messaging$.unsubscribe();

							//Finalisation du traitement
							subject.complete();

							//Récupération de l'extraction
							this.retrieveExtraction(extraction.idExtraction,idSession).pipe(take(1)).subscribe({
								next: response => {
									let baseFilename: string;

									//Définition de la partie principale du nom de fichier
									baseFilename = options.baseFilename || this.entiteService.translateEntityCode(extraction.entity.split('.').pop(-1));

									//Téléchargement du contenu
									this.attachmentService.downloadAttachment(response,null,'application/octet-stream',`${baseFilename}_${extractionDate}.${extraction.typeFormat.toLowerCase()}`);
								}
							});
						}
					})
					.onError({
						next: () => {
							//Fermeture des souscriptions
							messaging$.unsubscribe();

							//Finalisation du traitement
							subject.complete();
						}
					});
				} else
					//Message d'erreur
					this.toasterService.error(this.translateService.instant('actions.acces.accesNonAutorise'));
			}
		});

		//Retour de l'observable
		return subject.asObservable();
	}

	/**
	 * Récupération de la liste des entités
	 */
	getListeEntites(scope?: 'WORKFLOW' | 'CALENDRIER' | 'GROUPE' | 'CUSTOM_FORM' | 'WORKFLOW_MOTIF'): Observable<Array<any>> {
		//Récupération de la liste des entités disponibles
		return this.http.get<Array<any>>(`${environment.baseUrl}/controller/Extraction/findAllEntitesAvailable` + (scope ? '/' + scope : ''),{
			params: {
				idUser: this.user?.idUser || null
			}
		}).pipe(
			take(1),
			map(response => {
				//Retour de la liste des entités triées par libellé
				return response.map(dataFetchObject => ({
					code: dataFetchObject.type,
					state: dataFetchObject.state,
					icone: dataFetchObject.icone,
					iconeType: dataFetchObject.iconeType,
					mainObject: dataFetchObject.mainObject,
					route: dataFetchObject.route,
					routeKey: dataFetchObject.routeKey,
					listeTypesDroit: dataFetchObject.listeTypesDroit,
					libelle: this.entiteService.translateEntityCode(dataFetchObject.type.split('.').pop(-1)),
					autocomplete: this.autocompleteService.findAutocompleteForEntite(dataFetchObject.type)
				})).sort((a,b) => a.libelle?.localeCompare(b.libelle));
			}),
			shareReplay(1),
			catchError(error => {
				//Retour de la réponse en erreur
				return throwError(error);
			})
		);
	}

	/**
	 * Récupération de l'extraction
	 */
	retrieveExtraction(idExtraction,idSession) {
		//Récupération de l'extraction
		return this.http.post(`${environment.baseUrl}/controller/Extraction/retrieveExtraction/${idExtraction}/${idSession}`,null,{
			responseType: 'arraybuffer'
		});
	}

	/**
	 * Mise à jour de la recherche pour la sous-entité si nécessaire
	 */
	updateSearchSpecForSubEntity(searchSpec: any,entity: string,subEntityKey: string) {
		let entityName: string;
		let rootKey: string;

		//Vérification de la clé
		if (searchSpec && subEntityKey && subEntityKey.indexOf('.') != -1) {
			//Extraction du nom de l'entité
			entityName = entity.split('.').pop();

			//Récupération de la racine
			rootKey = subEntityKey.split('.')[0];

			//Vérification des filtres
			if (searchSpec.listeFilter?.length) {
				//Parcours des filtres
				searchSpec.listeFilter.forEach(f => {
					//Mise à jour de la clé
					f.clef = f.clef.split(',').map(key => key.indexOf(rootKey) == -1 ? rootKey + '.' + key : key).join(',');
				});
			}

			//Vérification de la présence d'une règle
			if (searchSpec.extraData) {
				//Modification de l'entité
				searchSpec.extraData.entite = entity;

				//Mise à jour des détails
				this.updateListeDetailsForSubFilter(searchSpec.extraData.listeDetails,entityName,rootKey);
			}
		}
	}

	/**
	 * Mise à jour d'une règle pour adapter les clés de recherche par rapport à la sous-entité
	 */
	updateListeDetailsForSubFilter(listeDetails,entityName,rootKey) {
		//Vérification des détails
		if (listeDetails?.length) {
			//Parcours récursifs des détails
			listeDetails.forEach(detail => {
				//Vérification du type de détail
				if (detail?.filter)
					//Mise à jour du filtre
					detail.filter.filter = entityName + '.' + rootKey + detail.filter.filter.substr(detail.filter.filter.indexOf('.'));
				else if (detail?.rule)
					//Mise à jour récursive
					this.updateListeDetailsForSubFilter(detail.rule.listeDetails,entityName,rootKey);
			});
		}
	}

	/**
	 * Ajout de l'action 'Extraire' à la liste (si disponible)
	 */
	addActionToListe(liste: ListView<any,any>,mapEntites: { [entite: string]: string }) {
		//Vérification de l'existence d'une extraction
		this.isExtractionAvailableFor(Object.keys(mapEntites)).subscribe({
			next: isAvailable => {
				//Ajout de l'action à la liste
				isAvailable && liste.listeActions.push({
					icon: 'download',
					onPress: (actionMasse: ActionMasse,messagingOptions: MessagingOptions) => this.selectExtraction(mapEntites,null,{ actionMasse,messagingOptions })
				});
			}}
		);
	}

	/**
	 * Lancement de l'extraction
	 */
	selectExtraction(mapEntites: { [entite: string]: string },entite?: string,options: { actionMasse?: ActionMasse,dashboardChart?: any,messagingOptions?: MessagingOptions,ruleOverride?: any,baseFilename?: string } = {}) {
		let searchSpec: any;
		let listeEntites: string[];

		//Récupération de la liste des entités
		listeEntites = mapEntites ? Object.keys(mapEntites) : null;

		//Récupération de la recherche
		searchSpec = options.actionMasse?.searchSpec;

		//Ouverture de la popup de sélection d'une extraction
		this.getListeExtractionsForEntite(entite,listeEntites,!!options.actionMasse,!!options.dashboardChart?.idFilteringObject).pipe(filter(result => !!result?.extraction)).subscribe({
			next: result => {
				let filter: Filter;

				//Vérification de l'action de masse
				if (options.actionMasse) {
					//Vérification du mode de sélection
					if (options.actionMasse.typeActionMasse == 'SELECTION') {
						//Définition du filtre
						filter = {
							clef: mapEntites[result.extraction.entity],
							listeObjects: options.actionMasse.listeIdObjects,
							typeComparaison: TypeComparaison.IN,
							type: TypeFilter.LONG
						};

						//Vérification de la recherche
						if (searchSpec)
							//Ajout du filtre
							searchSpec.listeFilter.push(filter)
						else
							//Définition de al recherche
							searchSpec = { listeFilter: [filter] };
					} else if (options.actionMasse.typeActionMasse == 'FULL' && !searchSpec)
						//Création d'une recherche vide
						searchSpec = {};

					//Mise à jour de la recherche si nécessaire
					this.updateSearchSpecForSubEntity(searchSpec,result.extraction.entity,mapEntites[result.extraction.entity]);
				}

				//Vérification que l'entité filtrante doit être ignorée
				if (options.dashboardChart && !result.isKeepFilteringObject) {
					//Retrait du filtre
					options.dashboardChart.idFilteringObject = null;
					options.dashboardChart.filteringEntity = null;
				}

				//Déclenchement de l'extraction
				this.executeExtraction(result.extraction,{ ...options,searchSpec }).subscribe();
			}
		});
	}

	/**
	 * Suppression du cache
	 */
	public resetExtractionsForEntiteCache() {
		//Suppression du cache
		this.mapExtractionsForEntite = {};
	}

	/**
	 * Enregistrement d'un ordonnancement pour un utilisateur
	 */
	public saveUserScheduler(userScheduler: any): Observable<Result> {
		//Enregistrement de l'ordonnancement pour un utilisateur
		return this.http.put<Result>(`${environment.baseUrl}/controller/Extraction/saveExtractionScheduler`,userScheduler);
	}

	/**
	 * Chargement d'une extraction
	 */
	public loadExtraction(idExtraction: any): Observable<any> {
		//Chargement de l'extraction
		return this.http.post<any>(`${environment.baseUrl}/controller/Extraction/loadExtraction/${idExtraction}`,null);
	}

	/**
	 * Enregistrement d'une extraction
	 */
	public saveExtraction(extraction: any): Observable<any> {
		//Enregistrement de l'extraction
		return this.http.put<any>(`${environment.baseUrl}/controller/Extraction/saveExtraction`,extraction);
	}

	/**
	 * Liste des types de diffusion
	 */
	public getListeTypesDiffusion(): Array<any> {
		//Retour de la liste des types de diffusion
		return this.listeTypesDiffusion.map(code => ({
			code,
			libelle: this.translateService.instant(`extraction.typeDiffusion.${code}`)
		}));
	}

	/**
	 * Récupération de la liste des types de format de sortie
	 */
	public getListeTypesFormat(): Array<{ code: string,libelle: string }> {
		//Création de la correspondance entre un code et son libellé
		return this.listeTypesFormat.map(code => ({
			code,
			libelle: this.translateService.instant('extraction.typeFormat.'+code)
		}));
	}
}