import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Result } from 'src/app/domain/common/http/result';
import { TypeFrequence,TypeJour,TypeMois,TypePositionJour } from 'src/app/domain/scheduler/scheduler';
import { environment } from 'src/environments/environment';
import { SchedulerComponent } from './scheduler.component';

@Injectable()
export class SchedulerService {
	/** Liste des fréquences **/
	private listeFrequences: Array<TypeFrequence> = Object.values(TypeFrequence);

	/** Liste des jours **/
	private listeJours: Array<TypeJour> = Object.values(TypeJour);

	/** Liste des mois **/
	private listeMois: Array<TypeMois> = Object.values(TypeMois);

	/** Liste des types de position des jours **/
	private listeTypesPositionJour: Array<TypePositionJour> = Object.values(TypePositionJour);

	/**
	 * Constructeur
	 */
	constructor(private bsModalService: BsModalService,private http: HttpClient) { }

	/**
	 * Récupération de la liste des fréquences
	 */
	getListeFrequences(): Array<TypeFrequence> { return this.listeFrequences; }

	/**
	 * Récupération de la liste des fréquences
	 */
	getListeJours(): Array<TypeJour> { return this.listeJours; }

	/**
	 * Récupération de la liste des mois
	 */
	getListeMois(): Array<TypeMois> { return this.listeMois; }

	/**
	 * Récupération de la liste des positions de jours
	 */
	getListeTypesPositionJour(): Array<TypePositionJour> { return this.listeTypesPositionJour; }

	/**
	 * Affichage de la popup de sélection de l'ordonnancement
	 */
	showScheduler(scheduler: any,isModification?: boolean,options? : { listeTypesFrequence?: Array<TypeFrequence> }): Observable<any> {
		let bsModalRef: BsModalRef<SchedulerComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(SchedulerComponent,{
			initialState: {
				scheduler: scheduler && cloneDeep(scheduler) || {
					type: TypeFrequence.NON_DEFINI
				},
				options,
				isModification
			}
		});

		//Retour de l'observable avec le résultat
		return bsModalRef.onHidden.pipe(map(() => bsModalRef.content.selectedScheduler));
	}

	/**
	 * Mise à jour d'un scheduler pour un utilisateur
	 */
	public updateUserScheduler(idUserScheduler: number,scheduler: any): Observable<Result> {
		//Mise à jour d'un scheduler pour un utilisateur
		return this.http.put<Result>(`${environment.baseUrl}/controller/Notification/updateUserScheduler/${idUserScheduler}`,scheduler);
	}

	/**
	 * Suppression d'un scheduler pour un utilisateur
	 */
	public deleteUserScheduler(userScheduler: any): Observable<Result> {
		//Suppression d'un scheduler pour un utilisateur
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Notification/deleteUserScheduler/${userScheduler.idUserScheduler}`);
	}
}