import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { filter,first,map,Observable,Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';
import { ImportTemplateComponent } from './import-template.component';
import { TypeModule } from 'src/app/domain/connecteur/type-module';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { ImportTemplateExpediteursComponent } from './import-template-expediteurs.component';
import { ImportTemplateEmailComponent } from './import-template-email.component';

@Injectable()
export class ImportTemplateService {
	/** Liste des types de format **/
	private readonly listeTypesFormat: Array<string> = ['CSV','XLSX'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private bsModalService: BsModalService,private translateService: TranslateService,private attachmentService: AttachmentService,private messagingService: MessagingService) {}

	/**
	 * Récupération de la liste des modules
	 */
	public retrieveListeModules(): Observable<Array<any>> {
		//Récupération de la liste des modules
		return this.http.post<Result>(`${environment.baseUrl}/controller/ImportTemplate/retrieveListeModules`,null).pipe(
			map(result => result?.data?.listeModules || [])
		);
	}

	/**
	 * Affichage de la popup de création d'un template d'import
	 */
	public showImportTemplate(typeModule: TypeModule,format: any,importTemplate?: any): Observable<any> {
		let bsModalRef: BsModalRef<ImportTemplateComponent>;

		//Ouverture de la popup de création/modification d'un template d'import
		bsModalRef = this.bsModalService.show(ImportTemplateComponent,{
			initialState: {
				importTemplate: cloneDeep(importTemplate) || {
					typeFormat: 'CSV',
					listeFields: []
				},
				format,
				typeModule,
				listeAvailableFields: cloneDeep(format.listeFields)
			},
			class: 'modal-lg'
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			first(),
			map(() => bsModalRef.content?.result?.importTemplate),
			filter(importTemplate => !!importTemplate)
		);
	}

	/**
	 * Récupération de la liste des types de format
	 */
	public getListeTypesFormat(): Array<{ code: string,libelle: string }> {
		//Liste des types de format
		return this.listeTypesFormat.map(code => ({
			code,
			libelle: this.translateService.instant('connecteur.import.template.typeFormat.'+code)
		}));
	}

	/**
	 * Enregistrement d'un template d'import
	 */
	public saveImportTemplate(importTemplate: any): Observable<Result> {
		//Enregistrement d'un template d'import
		return this.http.put<Result>(`${environment.baseUrl}/controller/ImportTemplate/saveImportTemplate`,importTemplate);
	}

	/**
	 * Suppression d'un template d'import
	 */
	public deleteImportTemplate(importTemplate: any): Observable<Result> {
		//Suppression d'un template d'import
		return this.http.delete<Result>(`${environment.baseUrl}/controller/ImportTemplate/deleteImportTemplate/${importTemplate.idTemplate}`);
	}

	/**
	 * Déclenchement de la génération du fichier d'exemple
	 */
	public generateSampleFile(importTemplate: any): Observable<any> {
		let subject: Subject<any> = new Subject<any>();
		let messaging$: MessagingObservables;

		//Démarrage de la génération du fichier d'exemple par websocket
		messaging$ = this.messagingService.init({
			entryPoint: `controller/ImportTemplate/generateSampleFile/${importTemplate.idTemplate}`,
			progressConfig: {
				libelle: `${importTemplate.libelle}.${importTemplate.typeFormat?.toLowerCase()}`,
				icone: 'dataset'
			}
		}).onFinish({
			next: ({ idSession }) => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Finalisation du traitement
				subject.complete();

				//Récupération du fichier d'exemple
				this.retrieveSampleFile(importTemplate,idSession).pipe(first()).subscribe({
					next: response => {
						let fileName: string;

						//Définition du nom de fichier
						fileName = `${importTemplate.libelle}.${importTemplate.typeFormat?.toLowerCase()}`;

						//Téléchargement du contenu
						this.attachmentService.downloadAttachment(response,null,'application/octet-stream',fileName);
					}
				});
			}
		}).onError({
			next: () => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Finalisation du traitement
				subject.complete();
			}
		});

		//Retour de l'observable
		return subject.asObservable();
	}

	/**
	 * Récupération du fichier d'exemple
	 */
	private retrieveSampleFile(importTemplate: any,idSession: string) {
		//Récupération du fichier d'exemple
		return this.http.post(`${environment.baseUrl}/controller/ImportTemplate/retrieveSampleFile/${importTemplate.idTemplate}/${idSession}`,null,{
			responseType: 'arraybuffer'
		});
	}

	/**
	 * Affichage de la liste des expéditeurs du template d'import
	 */
	public showExpediteurs(importTemplate: { listeAuthorizedEmails: Array<string> }): Observable<Array<string>> {
		let bsModalRef: BsModalRef<ImportTemplateExpediteursComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(ImportTemplateExpediteursComponent,{
			initialState: {
				listeEmails: (importTemplate.listeAuthorizedEmails || []).map(e => ({ value: e }))
			},
			class: 'modal-lg'
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			map(() => bsModalRef.content?.result?.listeEmails),
			filter(listeEmails => !!listeEmails)
		);
	}

	/**
	 * Affichage du mail de réception des fichiers d'import
	 */
	public showEmail(email: string): Observable<any> {
		let bsModalRef: BsModalRef<ImportTemplateEmailComponent>;

		//Ouverture de la pop-up
		bsModalRef = this.bsModalService.show(ImportTemplateEmailComponent,{
			initialState: {
				email
			}
		});

		//Retour du résultat
		return bsModalRef.onHidden;
	}
}