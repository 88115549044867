<div class="modal-header">
	<h4 class="modal-title"><span translate>connecteur.import.template.email.title</span></h4>
</div>
<div class="modal-body">
	<div class="form-group">
		<label>
			<span translate>connecteur.import.template.email.item</span>
			<icon class="m-l-5" name="info_outline" [tooltip]="'connecteur.import.template.email.info' | translate"></icon>
		</label>
		<div><span class="bold">{{ email }}</span></div>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
</div>