<div class="container-fluid" *ngIf="!dashboard && user && !isLoading">
	<div class="card detail">
		<div class="card-body card-padding">
			<h4 translate [translateParams]="{ user: user.prenom + ' ' + user.nom }">dashboard.welcome.titre</h4>
			<h5 translate>dashboard.welcome.message</h5>
		</div>
	</div>
</div>
<div class="dashboard" [ngClass]="{ 'container-fluid': !idObjet && !profil }" *ngIf="dashboard && !isLoading">
	<ul class="liste_filters" *ngIf="!isEdition && listeSharedFilters?.length">
		<li *ngFor="let filter of listeSharedFilters">
			<span class="label label-info">
				<span>
					<span class="value">{{ filter.libelle }}</span>
				</span>
				<icon name="close" class="clickable" (click)="removeSharedFilter(filter.idDefinition)"></icon>
			</span>
		</li>
	</ul>

	<form #dashboardForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<mat-button-toggle-group *ngIf="isEdition" class="pull-right m-t-10 m-b-10" [value]="typeLayout">
			<mat-button-toggle (click)="changeLayout(TypeLayout.MOBILE);" value="MOBILE">
				<icon name="smartphone" class="m-r-10"></icon>
				<span translate>dashboard.layout.MOBILE</span>
			</mat-button-toggle>
			<mat-button-toggle (click)="changeLayout(TypeLayout.TABLETTE);" value="TABLETTE">
				<icon name="tablet_mac" class="m-r-10"></icon>
				<span translate>dashboard.layout.TABLETTE</span>
			</mat-button-toggle>
			<mat-button-toggle (click)="changeLayout(TypeLayout.WEB);" value="WEB">
				<icon name="desktop_mac" class="m-r-10"></icon>
				<span translate>dashboard.layout.WEB</span>
			</mat-button-toggle>
		</mat-button-toggle-group>
		<div class="clearfix"></div>
		<div>
			<div class="row" [ngClass]="{ 'layout-size': isEdition && typeLayout != 'WEB','mobile': isEdition && typeLayout == 'MOBILE','tablette': isEdition && typeLayout == 'TABLETTE' }" [ngStyle]="{ 'height': staticDashboardHeight }" *ngIf="!isEdition && listeStaticCharts?.length">
				<gridster [options]="staticGridOptions">
					<gridster-item [item]="staticChart" *ngFor="let staticChart of listeStaticCharts">
						<div class="card detail handle">
							<div class="card-body">
								<dashboard-chart [chartOptions]="staticChart.options" [data]="staticChart.data"></dashboard-chart>
							</div>
						</div>
					</gridster-item>
				</gridster>
			</div>
			<div class="row" #dashboardContainer [ngClass]="{ 'layout-size': isEdition && typeLayout != 'WEB','mobile': isEdition && typeLayout == 'MOBILE','tablette': isEdition && typeLayout == 'TABLETTE' }" [ngStyle]="{ 'height': dashboardHeight }">
				<div *ngIf="dashboard.listeDashboardCharts?.length == 0 && !isEdition" class="empty-state">
					<icon name="insert_chart" class="icon-hc-5x"></icon>
					<h3 translate>dashboard.emptyState.title</h3>
					<p translate>dashboard.emptyState.message</p>
				</div>
				<gridster [options]="gridOptions" [ngClass]="{ 'editing': isEdition }">
					<gridster-item [item]="dashboardChart" *ngFor="let dashboardChart of dashboard.listeDashboardCharts" (mouseenter)="dashboardChart.zIndex = 2" (mouseleave)="dashboardChart.zIndex = undefined" [ngStyle]="{ 'z-index': dashboardChart.zIndex || 1 }">
						<div class="card detail handle" *ngIf="dashboardChart?.chart">
							<div class="card-body">
								<dashboard-chart [dashboardChart]="dashboardChart" [isEdition]="isEdition" [idFilteringObject]="idObjet" [filteringEntity]="filteringEntity" (onDelete)="onDashboardChartDeletion($event)" (onCustomize)="onDashboardChartCustomization($event)" (onDashboardChartDuplicated)="onDashboardChartDuplicated($event)"></dashboard-chart>
							</div>
						</div>
						<div class="card detail handle" *ngIf="dashboardChart?.calendrier">
							<div class="card-body">
								<dashboard-calendar [dashboardChart]="dashboardChart" [isEdition]="isEdition" (onDelete)="onDashboardChartDeletion($event)" (onCustomize)="onDashboardChartCustomization($event)" (onDashboardChartDuplicated)="onDashboardChartDuplicated($event)"></dashboard-calendar>
							</div>
						</div>
					</gridster-item>
				</gridster>
			</div>
			<div class="listview m-b-20" style="margin-top: 80px;" *ngIf="isEdition">
				<div class="lv-body">
					<div class="lv-item media text-center lv-item-add clickable" matRipple (click)="addRows();">
						<h5>
							<a [routerLink]="[]" translate>dashboard.actions.ajouterLignes</a>
						</h5>
					</div>
				</div>
			</div>
			<div *ngIf="isEdition" class="card-footer m-b-20">
				<span class="pull-right">
					<button mat-raised-button color="primary" (click)="saveDashboard()">
						<span translate>actions.enregistrer</span>
					</button>
				</span>
				<button mat-button [formDetectChanges]="{ doAction: close,isDirty: isDirty() }">
					<span translate>actions.fermer</span>
				</button>
			</div>
		</div>
	</form>
</div>

<floating-button *ngIf="dashboard != null && !isEdition">
	<floating-button-item icon="edit" [label]="'dashboard.actions.modifier' | translate" (click)="editDashboard()"></floating-button-item>
	<floating-button-item *ngIf="dashboard?.idDashboard" icon="delete" [label]="'dashboard.actions.supprimer' | translate" (click)="deleteDashboard()"></floating-button-item>
	<floating-button-item *ngIf="!idObjet && typeDashboard" icon="format_list_bulleted" [label]="'dashboard.actions.accederListe' | translate" (click)="dashboardService.showListe(typeDashboard)"></floating-button-item>
	<ng-container *ngIf="!idObjet">
		<floating-button-item *ngFor="let action of listeExtraActions" [icon]="action.icone" [label]="action.libelleKey | translate" (click)="action.doAction()"></floating-button-item>
	</ng-container>
	<floating-button-item icon="download" [label]="'actions.extraire' | translate" (click)="showExtraction()"></floating-button-item>
</floating-button>
<floating-button *ngIf="dashboard != null && isEdition" defaultIcon="add" [isAlwaysShown]="true" (click)="addChart()"></floating-button>