import { Component,ViewChild,TemplateRef,ViewContainerRef,AfterViewInit,EmbeddedViewRef,ChangeDetectorRef,OnInit } from '@angular/core';
import { Router,Data } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable,combineLatest } from 'rxjs';
import { map,startWith } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { ExtendedRouteData } from 'src/app/domain/common/route/extended-route';
import { Session } from 'src/app/domain/security/session';
import { LayoutService } from './layout.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { MailHistoryService } from 'src/app/share/components/mail-history/mail-history.service';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { AttachmentEntity } from 'src/app/domain/attachment/attachment';
import { TypeAttachment,TypeAttachmentContext } from 'src/app/domain/attachment/type-attachment';
import { EntiteService } from 'src/app/share/components/entite/entite.service';
import { PageTitleService } from './page-title.service';
import { DashboardService } from 'src/app/components/dashboard/dashboard.service';
import { DocumentService } from 'src/app/share/components/document/document.service';
import { LoaderService } from './loader/loader.service';

@Component({
	selector: 'page-title',
	templateUrl: './page-title.component.html'
})
export class PageTitleComponent implements OnInit,AfterViewInit {
	/** Template du composant **/
	@ViewChild('pageTitleTemplate')
	private pageTitleTemplate: TemplateRef<any>;

	/** Récepteur de composant **/
	@ViewChild('viewContainer',{ read: ViewContainerRef })
	private viewContainer: ViewContainerRef;

	/** Session **/
	session: Session = null;

	/** Titre de la page **/
	title$: Observable<string>;

	/** Paramètres de la route **/
	routeData$: Observable<Data>;

	/** Fil d'ariane **/
	filAriane$: Observable<string>;

	/** Compteur de pièces jointes **/
	attachmentState$: Observable<{ typeAttachment: TypeAttachment,nbAttachments: number }>;

	/** Liste des sélecteurs **/
	listeSelectors$: Observable<Array<{ libelle: string,doAction: Function }>>;

	/** Liste des actions **/
	listeActions$: Observable<Array<{ libelle: string,icon: string,iconType?: string,doAction: Function }>>;

	/**
	 * Constructeur
	 */
	constructor(public router: Router,private store: Store<AppState>,private translateService: TranslateService,private changeDetectorRef: ChangeDetectorRef,private historyService: HistoryService
			,private mailHistoryService: MailHistoryService,private attachmentService: AttachmentService,private entiteService: EntiteService,private pageTitleService: PageTitleService,private documentService: DocumentService
			,public dashboardService: DashboardService,public layoutService: LayoutService,public loaderService: LoaderService) {
		//Récupération du titre de la page
		this.title$ = this.store.select(state => state.layout?.title);

		//Sélection de la session
		this.store.select<Session>(s => s.session).subscribe(session => this.session = session);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Récupération des paramètres de la route active
		this.routeData$ = this.store.select<ExtendedRouteData>(s => s.layout?.activatedRouteData);

		//Récupération du fil d'ariane
		this.filAriane$ = this.store.select<string>(s => s.layout?.filAriane);

		//Récupération du compteur de pièces jointes
		this.attachmentState$ = this.attachmentService.attachmentSubject.asObservable();

		//Récupération de la liste des sélecteurs
		this.listeSelectors$ = this.pageTitleService.selectorsSubject.asObservable();

		//Récupération de la liste des actions
		this.listeActions$ = this.pageTitleService.actionsSubject.asObservable();
	}

	/**
	 * Détection de l'affichage de la vue
	 */
	ngAfterViewInit() {
		let view: EmbeddedViewRef<any>;

		//Écoute des changements du titre
		combineLatest([this.title$
			,this.layoutService.routeChangeSubject.pipe(
				map(d => d.state),
				startWith(null as string)
			)
		]).pipe(startWith([null,null] as [string,string])).subscribe(([title,state]) => {
			//Vérification du state de la route activée
			if (title === null && state === null || state && state != 'ng-blank') {
				//Remise à zéro du compteur de pièces jointes
				this.attachmentService.reset();

				//Remise à zéro des sélecteurs
				this.pageTitleService.resetListeSelectors();

				//Remise à zéro des actions
				this.pageTitleService.resetListeActions();

				//Remise à zéro du dashboard embarqué
				this.dashboardService.resetEmbeddedDashboardMode();

				//Retrait du composant
				this.viewContainer.clear();

				//Recréation du composant
				view = this.pageTitleTemplate.createEmbeddedView(null);

				//Insertion du composant
				this.viewContainer.insert(view);

				//Détection des changements
				this.changeDetectorRef.detectChanges();
			}
		});
	}

	/**
	 * Retour en arrière
	 */
	goBack(state: string) {
		//Retour en arrière
		this.layoutService.goBack(state);
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		let routeComponent: any;

		//Récupération de l'instance du composant de la route courante
		routeComponent = this.layoutService.getRouteComponentInstance();

		//Vérification du type de l'objet instancié dans la route
		if (this.entiteService.isIEntity(routeComponent))
			//Affichage de l'historique des modifications
			this.historyService.showHistory(routeComponent.getClassName(),routeComponent.getIdObject(),routeComponent.getIdSecondary?.());
	}

	/**
	 * Affichage de l'historique des mails
	 */
	showMailHistory() {
		let routeComponent: any;

		//Récupération de l'instance du composant de la route courante
		routeComponent = this.layoutService.getRouteComponentInstance();

		//Vérification du type de l'objet instancié dans la route
		if (this.entiteService.isIEntity(routeComponent))
			//Affichage de l'historique des mails
			this.mailHistoryService.showMailHistory(routeComponent.getClassName(),routeComponent.getIdObject(),routeComponent.getIdSecondary?.());
	}

	/**
	 * Génération d'un document PDF
	 */
	generateDocument() {
		let routeComponent: any;

		//Récupération de l'instance du composant de la route courante
		routeComponent = this.layoutService.getRouteComponentInstance();

		//Vérification du type de l'objet instancié dans la route
		if (this.entiteService.isIEntity(routeComponent))
			//Génération du document PDF
			this.documentService.generateDocument(routeComponent.getClassName(),routeComponent.getIdObject());
	}

	/**
	 * Affichage des pièces jointes
	 */
	showAttachment() {
		let routeComponent: any;

		//Récupération de l'instance du composant de la route courante
		routeComponent = this.layoutService.getRouteComponentInstance();

		//Vérification du type de l'objet instancié dans la route
		if (this.isAttachmentEntity(routeComponent)) {
			//Affichage de la popup de gestion des pièces jointes
			this.attachmentService.showAttachment({
				owningEntity: routeComponent.getOwningEntity(),
				typeAttachment: routeComponent.typeAttachment,
				idObjet: routeComponent.getOwningEntity()?.[TypeAttachmentContext[routeComponent.typeAttachment].key] || null,
				typeLinkClass: routeComponent.typeLinkClass,
				typeLink: routeComponent.typeLink
			});
		}
	}

	/**
	 * Récupération du titre du composant
	 */
	getTitleFromComponent(): string {
		//Traduction du titre
		return typeof this.layoutService.getExtendedRouteData()?.title == 'function' ? (this.layoutService.getExtendedRouteData()?.title as (componentInstance: any,translateService: TranslateService) => string)(this.layoutService.getRouteComponentInstance(),this.translateService) : null;
	}

	/**
	 * Vérification du type 'AttachmentEntity'
	 */
	private isAttachmentEntity(instance: any): instance is AttachmentEntity {
		//Vérification du type 'AttachmentEntity'
		return (instance as AttachmentEntity).getOwningEntity !== undefined && (instance as AttachmentEntity).typeAttachment !== undefined;
	}
}