import { AfterContentChecked,ChangeDetectorRef,Component,Input,OnInit } from '@angular/core';
import { isEqual } from 'lodash-es';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Filter,ListView } from 'src/app/domain/common/list-view';
import { Action,SuccessiveSearchItem,SuccessiveSearchOptions } from 'src/app/domain/successive-search/successive-search';

@Component({
	selector: 'successive-search',
	templateUrl: './successive-search.component.html'
})
export class SuccessiveSearchComponent implements OnInit,AfterContentChecked {
	/** Options de la recherche successive **/
	@Input() options: SuccessiveSearchOptions;

	/** Compteur du nombre d'éléments à intégrer **/
	nbSelectedItems: number = 0;

	/** Niveaux de recherche ouverts **/
	listeOpenedLevels: Array<SuccessiveSearchItem> = [];

	/** Index courant du niveau **/
	currentLevelIndex: number = 0;

	/** Filtres successifs à appliquer aux niveaux de recherche **/
	listeCumulatedFilters: Array<any> = [];

	/** Liste des éléments sélectionnés regroupés par groupe **/
	listeSelectionsItems: Array<any> = [];

	/** Liste des listes d'éléments à afficher **/
	listeLevelLists: Array<ListView<any,any>> = [];

	/** Indicateur de conservation du filtre sur l'entité filtrante (utilisé pour les extractions) **/
	isKeepFilteringObject: boolean = true;

	/** Résultat de la recherche successive **/
	result: { isDismissed?: boolean,listeSelectionsItems?: Array<any>,nbSelectedItems?: number,isKeepFilteringObject?: boolean };

	/**
	 * Constructeur
	 */
	constructor(private bsModalRef: BsModalRef<SuccessiveSearchComponent>,private changeDetectorRef: ChangeDetectorRef) {
		//Binding des méthodes
		this.close = this.close.bind(this);
		this.getUri = this.getUri.bind(this);
		this.onRefresh = this.onRefresh.bind(this);
		this.getListeFilters = this.getListeFilters.bind(this);
		this.getListeStaticFilters = this.getListeStaticFilters.bind(this);
		this.getDefaultOrder = this.getDefaultOrder.bind(this);
		this.isActionVisible = this.isActionVisible.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Suppression des keywords dans la recherche
		this.options.search.listeFilter.forEach(f => {
			//Vérification de la présence de 'keyword'
			if (f.clef.indexOf('keyword') != -1)
				//Suppression du terme '.keyword' en fin de chaîne
				f.clef = f.clef.replaceAll(/.keyword$/g,'');
		});

		//Parcours des niveaux pour définir le niveau de groupe sélectionnable et le niveau d'élément sélectionnable
		this.options.listeSearchLevels.forEach((level: SuccessiveSearchItem,index: number,array) => {
			//Vérification du dernier niveau
			if (index == array.length - 1) {
				//Définition de la sélection des éléments
				level.isSelectableItem = true;
				level.isSelectableGroupe = false;
			} else if (index == array.length - 2) {
				//Définition de la sélection des groupes
				level.isSelectableItem = false;
				level.isSelectableGroupe = true;
			} else {
				//Définition des indicateurs de non-sélection
				level.isSelectableItem = false;
				level.isSelectableGroupe = false;

				//Activation du mode multi groupe
				this.options.isMultiGroupe = true;
			}
		})

		//Initialisation des niveaux de recherche ouverts
		this.listeOpenedLevels.push(this.options.listeSearchLevels[this.currentLevelIndex]);

		//Initialisation de la liste pour le niveau ouvert
		this.listeLevelLists.push(this.initListViewForLevel(this.currentLevelIndex));
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Récupération de l'uri en fonction du niveau
	 */
	private getUri(levelIndex: number): string {
		//Récupération de l'uri en fonction du niveau
		return this.options.listeSearchLevels[levelIndex].uri();
	}

	/**
	 * Appel du rafraichissement en fonction du niveau
	 */
	private onRefresh(liste: ListView<any,any>) {
		//Rafraichissement des groupes sélectionnés
		this.options.listeSearchLevels[this.currentLevelIndex]?.isSelectableGroupe && this.refreshSelectableGroupesSelection(liste);

		//Rafraichissement des éléments sélectionnés
		this.options.listeSearchLevels[this.currentLevelIndex]?.isSelectableItem && this.refreshSelectableItemsSelection(liste);
	}

	/**
	 * Récupération de la recherche par défaut
	 */
	private getListeFilters(levelIndex: number): Array<Filter> {
		//Récupération de la recherche par défaut en fonction du niveau
		return this.options.listeSearchLevels[levelIndex].getListeFilters?.();
	}

	/**
	 * Récupération de l'ordre par défaut
	 */
	private getDefaultOrder(levelIndex: number): string {
		//Récupération de l'ordre par défaut en fonction du niveau
		return this.options.listeSearchLevels[levelIndex].defaultOrder;
	}

	/**
	 * Récupération des filtres
	 */
	private getListeStaticFilters(levelIndex: number): Array<Filter> {
		//Récupération des filtres
		return this.options.listeSearchLevels[levelIndex].getListeFiltersExtended?.(this.options,this.listeCumulatedFilters) || [];
	}

	/**
	 * Initialisation d'une ListView pour un niveau de recherche
	 */
	private initListViewForLevel(levelIndex: number): ListView<any,any> {
		let liste: ListView<any,any> = null;
		let listeFilters: Array<Filter>;
		let listeStaticFilters: Array<Filter>;
		let defaultOrder: string;
		let uri: string;

		//Récupération des informations de recherche
		uri = this.getUri(levelIndex);
		listeFilters = this.getListeFilters(levelIndex);
		listeStaticFilters = this.getListeStaticFilters(levelIndex);
		defaultOrder = this.getDefaultOrder(levelIndex);

		//Alimentation des informations de la liste
		liste = new ListView<any,any>({
			uri,
			component: null,
			isLocal: true,
			onRefresh: this.onRefresh,
			isContentHidden: true,
			defaultOrder,
			listeFilters,
			listeStaticFilters
		});

		//Retour de la liste
		return liste;
	}

	/**
	* Gestion de l'ouverture d'un niveau de recherche
	*/
	public openLevel(selectedGroupe: any) {
		let filterForNextLevel: any;
		let searchLevelToOpen: any;
		let currentLevelIndex: number;
		let newLevelIndex: number;

		//Recherche de la position du niveau de recherche actuel
		currentLevelIndex = this.listeOpenedLevels.findIndex(level => level.type == this.options.typeSearchLevel);

		//Mise à jour du libellé ouvert
		this.listeOpenedLevels[currentLevelIndex].libelleDynamique = this.listeOpenedLevels[currentLevelIndex].getLibelleDynamique?.(selectedGroupe) || selectedGroupe.libelleGroupe;

		//Récupération du filtrage à appliquer au niveau suivant selon l'élément choisi
		filterForNextLevel = this.listeOpenedLevels[currentLevelIndex].getAssociatedFilter(selectedGroupe.idGroupe || selectedGroupe.libelleGroupe,selectedGroupe);

		//Cumul du filtre associé au niveau courant et de ceux des niveaux précédents
		this.listeCumulatedFilters.push(filterForNextLevel);

		//Incrémentation de l'index
		newLevelIndex = currentLevelIndex + 1;

		//Récupération du niveau de recherche suivant
		searchLevelToOpen = this.options.listeSearchLevels[newLevelIndex];

		//Ajout du niveau de recherche suivant à la liste des niveaux ouverts
		this.listeOpenedLevels.push(searchLevelToOpen);

		//Définition du niveau de recherche à afficher
		this.options.typeSearchLevel = searchLevelToOpen.type;

		//Initialisation de la liste pour le niveau ouvert
		this.listeLevelLists.push(this.initListViewForLevel(newLevelIndex));

		//Recherche de la position du niveau de recherche actuel après changement de niveau
		this.currentLevelIndex = newLevelIndex;
	}

	/**
	 * Gestion du retour à un niveau de recherche précédent
	 */
	public returnToLevel(index: number) {
		//Retour sur le niveau de recherche choisi
		this.listeOpenedLevels.length = index + 1;

		//Suppression des filtres des niveaux fermés
		this.listeCumulatedFilters.length = index;

		//Définition du niveau de recherche à afficher
		this.options.typeSearchLevel = this.listeOpenedLevels.slice(-1)[0].type;

		//Réduction de la taille de la liste
		this.listeLevelLists.length = index + 1;

		//Définition de l'index courant
		this.currentLevelIndex = index;
	}

	/**
	 * Sélection d'un groupe
	 */
	public onSelectGroupe(selectableGroupe: any) {
		let itemsForSelectableGroupe: any;

		//Vérification du type de sélection actuel
		if (selectableGroupe.selected == 'ALL') {
			//Désélection de tous les éléments du groupe
			this.unselectAllItemsForSelectableGroupe(selectableGroupe);
		} else {
			//Récupération des éléments sélectionnés pour le groupe
			itemsForSelectableGroupe = this.getSelectionItemsForSelectableGroupe(selectableGroupe.idGroupe ? selectableGroupe.idGroupe : this.getMapKeyFromCumulatedFilters(selectableGroupe));

			//Sélection de tous les éléments du groupe
			this.selectAllItemsForSelectableGroupe(itemsForSelectableGroupe,selectableGroupe);
		}

		//Recalcul du nombre d'éléments à intégrer
		this.refreshNbSelectedItems();
	}

	/**
	 * Sélection de l'élément
	 */
	public selectItem(selectableItem: any) {
		//Vérification du mode de sélection
		if (!this.options.isSingleResult) {
			//Sélection/déselection de l'élément
			selectableItem.selected = !selectableItem.selected;

			//Mise à jour de la liste des éléments sélectionnés
			this.doSelectItem(selectableItem);
		} else {
			//Définition du résultat
			this.bsModalRef.content.result = {
				listeSelectionsItems: [selectableItem],
				nbSelectedItems: 1,
				isKeepFilteringObject: this.isKeepFilteringObject
			}

			//Fermeture de la pop-up
			this.bsModalRef.hide();
		}
	}

	/**
	 * Ajout d'un élément à la liste des éléments sélectionnés
	 */
	public doSelectItem(selectableItem: any) {
		let itemsForSelectableGroupe: any;
		let excludedIdIndex: number;
		let mapAggregationKeyValues: any;

		//Récupération de la sélection d'éléments pour le groupe
		itemsForSelectableGroupe = this.getSelectionItemsForSelectableGroupe(selectableItem[this.options.groupeKey] && this.options.idGroupeKey && selectableItem[this.options.groupeKey][this.options.idGroupeKey] || this.getMapKeyFromCumulatedFilters());

		//Vérification que l'élément a été sélectionné
		if (selectableItem.selected) {
			//Vérification que des éléments ont déjà été sélectionnés pour ce groupe
			if (itemsForSelectableGroupe) {
				//Récupération de l'index de l'élément dans la liste des identifiants désélectionnés
				excludedIdIndex = itemsForSelectableGroupe.listeExcludedIdItems.indexOf(selectableItem[this.options.idItemKey]);

				//Vérification que l'élément a été exclu
				if (excludedIdIndex > -1) {
					//Suppression dans la liste des éléments exclus
					itemsForSelectableGroupe.listeExcludedIdItems.splice(excludedIdIndex,1);

					//Vérification de l'identifiant secondaire
					if (this.options.idSecondaryItemKey)
						//Suppression dans la liste des éléments exclus
						itemsForSelectableGroupe.listeExcludedIdSecondaryItems.splice(excludedIdIndex,1);
				} else {
					//Ajout de l'identifiant de l'élément à la liste
					itemsForSelectableGroupe.listeIdItems.push(selectableItem[this.options.idItemKey]);

					//Vérification de 'identifiant secondaire
					if (this.options.idSecondaryItemKey)
						//Ajout de l'identifiant de l'élément à la liste
						itemsForSelectableGroupe.listeIdSecondaryItems.push(selectableItem[this.options.idSecondaryItemKey]);
				}
			} else {
				//Définition de la clé associée au groupe
				mapAggregationKeyValues = selectableItem[this.options.groupeKey] && this.options.idGroupeKey ? { [`${this.options.groupeKey}.${this.options.idGroupeKey}`]: selectableItem[this.options.groupeKey][this.options.idGroupeKey] } : this.getMapKeyFromCumulatedFilters();

				//Création d'une entrée dans la liste des éléments sélectionnés
				this.listeSelectionsItems.push({
					mapAggregationKeyValues: mapAggregationKeyValues,
					listeIdItems: [selectableItem[this.options.idItemKey]],
					listeIdSecondaryItems: [this.options.idSecondaryItemKey && selectableItem[this.options.idSecondaryItemKey]],
					listeExcludedIdItems: [],
					listeExcludedIdSecondaryItems: []
				});
			}
		} else {
			//Vérification que le groupe a été sélectionné entièrement
			if (itemsForSelectableGroupe.nbItemsForAggregator) {
				//Ajout de l'éléments à la liste des identifiants à exclure
				itemsForSelectableGroupe.listeExcludedIdItems.push(selectableItem[this.options.idItemKey]);

				//Vérification de l'identifiant secondaire
				if (this.options.idSecondaryItemKey)
					//Ajout de l'éléments à la liste des identifiants à exclure
					itemsForSelectableGroupe.listeExcludedIdSecondaryItems.push(selectableItem[this.options.idSecondaryItemKey]);

			} else {
				//Suppression dans la liste des éléments sélectionnés
				itemsForSelectableGroupe.listeIdItems = itemsForSelectableGroupe.listeIdItems.filter(id => id !== selectableItem[this.options.idItemKey]);

				//Vérification de l'identifiant secondaire
				if (this.options.idSecondaryItemKey)
					//Suppression dans la liste des éléments sélectionnés
					itemsForSelectableGroupe.listeIdSecondaryItems = itemsForSelectableGroupe.listeIdSecondaryItems.filter(id => id !== selectableItem[this.options.idSecondaryItemKey]);
			}
		}

		//Mise à jour du nombre d'éléments à intégrer
		this.nbSelectedItems += selectableItem.selected ? 1 : -1;
	}

	/**
	 * Récupération du suivi de l'affichage d'un élément par sa position
	 */
	trackByPosition(index: number) {
		//Retour de la position
		return index;
	}

	/**
	 * Désélection de tous les éléments d'un groupe
	 */
	private unselectAllItemsForSelectableGroupe(selectableGroupe: any) {
		let itemsForSelectableGroupeIndex: any;

		//Mise à jour de l'indicateur de sélection du groupe
		selectableGroupe.selected = 'NONE';
		selectableGroupe.isChecked = false;

		//Récupération de l'index de la liste des éléments sélectionnés pour le groupe
		itemsForSelectableGroupeIndex = this.getSelectionItemsForSelectableGroupe(selectableGroupe.idGroupe ? selectableGroupe.idGroupe : this.getMapKeyFromCumulatedFilters(selectableGroupe),true);

		//Vérification de l'index
		if (itemsForSelectableGroupeIndex > -1)
			//Suppression dans la liste des modèles sélectionnés
			this.listeSelectionsItems.splice(itemsForSelectableGroupeIndex,1);
	}

	/**
	 * Sélection complète des éléments d'un groupe
	 */
	private selectAllItemsForSelectableGroupe(itemsForSelectableGroupe: any,groupe: any) {
		let mapAggregationKeyValues: any;

		//Mise à jour du type de sélection (complète)
		groupe.selected = 'ALL';
		groupe.isChecked = true;

		//Vérification que des éléments ont déjà été sélectionnés pour ce groupe
		if (itemsForSelectableGroupe) {
			//Ajout de l'indicateur du nombre d'éléments associées au groupe
			itemsForSelectableGroupe.nbItemsForAggregator = groupe.nbItems;

			//Vidage des listes d'identifiants d'éléments sélectionnées individuellement
			itemsForSelectableGroupe.listeIdItems = [];
			itemsForSelectableGroupe.listeExcludedIdItems = [];
			itemsForSelectableGroupe.listeIdSecondaryItems = [];
			itemsForSelectableGroupe.listeExcludedIdSecondaryItems = [];
		} else {
			//Définition de la clé associée au groupe
			mapAggregationKeyValues = groupe.idGroupe ? { [`${this.options.groupeKey}.${this.options.idGroupeKey}`]: groupe.idGroupe } : this.getMapKeyFromCumulatedFilters(groupe);

			//Création d'une entrée dans la liste des sélections d'éléments
			this.listeSelectionsItems.push({
				mapAggregationKeyValues: mapAggregationKeyValues,
				listeIdItems: [],
				listeIdSecondaryItems: [],
				listeExcludedIdItems: [],
				listeExcludedIdSecondaryItems: [],
				nbItemsForAggregator: groupe.nbItems
			});
		}
	}

	/**
	 * Récupération de la sélection d'éléments pour un groupe donnée
	 */
	private getSelectionItemsForSelectableGroupe(id: any,isGetIndex?: boolean) {
		//Vérification du résultat attendu
		if (isGetIndex)
			//Retour des index des éléments sélectionnés pour le groupe
			return this.listeSelectionsItems.findIndex(s => typeof id == 'number' ? id === s.mapAggregationKeyValues[`${this.options.groupeKey}.${this.options.idGroupeKey}`] : isEqual(id,s.mapAggregationKeyValues));
		else
			//Retour des éléments sélectionnés pour le groupe
			return this.listeSelectionsItems.find(s => typeof id == 'number' ? id === s.mapAggregationKeyValues[`${this.options.groupeKey}.${this.options.idGroupeKey}`] : isEqual(id,s.mapAggregationKeyValues));
	}

	/**
	 * Recalcul du nombre d'éléments sélectionnés
	 */
	private refreshNbSelectedItems() {
		//Somme du nombre d'éléments sélectionnés pour chaque groupe
		this.nbSelectedItems = this.listeSelectionsItems.reduce((somme,itemsForSelectableGroupe) => {
			//Vérification que le groupe a été entièrement sélectionné
			if (itemsForSelectableGroupe.nbItemsForAggregator)
				//Ajout du nombre d'éléments dans le groupe, moins ceux qui ont été désélectionnés
				return somme + itemsForSelectableGroupe.nbItemsForAggregator - itemsForSelectableGroupe.listeExcludedIdItems.length;
			else
				//Ajout du nombre d'éléments sélectionnés
				return somme + itemsForSelectableGroupe.listeIdItems.length;
		},0);
	}

	/**
	 * Mise à jour du statut de sélection des groupes
	 */
	private refreshSelectableGroupesSelection(liste: ListView<any,any>) {
		let listeSelectionsItemForPage: Array<any>;
		let listeItemsBySelectableGroupe: Array<any>;

		//Récupération de la page des éléments par groupe
		listeItemsBySelectableGroupe = liste.data.content;

		//Récupération de la liste des éléments sélectionnés restreins aux groupes chargés
		listeSelectionsItemForPage = this.listeSelectionsItems.filter(s => listeItemsBySelectableGroupe.findIndex(g => g.idGroupe ? g.idGroupe === s.mapAggregationKeyValues[`${this.options.groupeKey}.${this.options.idGroupeKey}`] : isEqual(this.getMapKeyFromCumulatedFilters(g),s.mapAggregationKeyValues)) > -1);

		//Parcours de la liste des éléments sélectionnés
		listeSelectionsItemForPage.forEach(s => {
			let groupe: any;

			//Récupération du groupe dans la liste
			groupe = listeItemsBySelectableGroupe.find(g => g.idGroupe ? g.idGroupe === s.mapAggregationKeyValues[`${this.options.groupeKey}.${this.options.idGroupeKey}`] : isEqual(this.getMapKeyFromCumulatedFilters(g),s.mapAggregationKeyValues));

			//Vérification de l'état de la sélection
			if (groupe.nbItems == s.listeExcludedIdItems.length || !s.nbItemsForAggregator && !s.listeIdItems.length)
				//Désélection de tous les éléments du groupe
				this.unselectAllItemsForSelectableGroupe(groupe);
			else if (groupe.nbItems == s.listeIdItems.length || s.nbItemsForAggregator && !s.listeExcludedIdItems.length)
				//Sélection de tous les éléments du groupe
				this.selectAllItemsForSelectableGroupe(s,groupe);
			else
				//Mise à jour du type de sélection (partielle)
				groupe.selected = 'SOME';
		});
	}

	/**
	 * Mise à jour du statut de sélection des éléments
	 */
	private refreshSelectableItemsSelection(liste: ListView<any,any>) {
		let itemsForSelectableGroupe: any;

		//Récupération de la sélection d'éléments pour le groupe
		itemsForSelectableGroupe = (this.options.isMultiGroupe || this.listeCumulatedFilters[0]?.valeur) && this.getSelectionItemsForSelectableGroupe(this.options.isMultiGroupe ? this.getMapKeyFromCumulatedFilters() : this.listeCumulatedFilters[0].valeur);

		//Vérification que des éléments ont été sélectionnés
		if (itemsForSelectableGroupe) {
			//Mise à jour du statut de sélection des éléments
			liste.data.content = liste.data.content.map(item => {
				//Vérification que le groupe a été sélectionnée entièrement
				if (itemsForSelectableGroupe.nbItemsForAggregator)
					//Définition du statut de sélection (l'élément est sélectionné s'il n'est pas exclu)
					item.selected = itemsForSelectableGroupe.listeExcludedIdItems.indexOf(item[this.options.idItemKey]) == -1;
				else
					//Définition du statut de sélection (l'élément est sélectionné s'il est inclus)
					item.selected = itemsForSelectableGroupe.listeIdItems.indexOf(item[this.options.idItemKey]) > -1;

				return item;
			});
		}
	}

	/**
	 * Fermeture de la pop-up
	 */
	close() {
		//Aucun résultat
		this.bsModalRef.content.result = null;

		//Fermeture de la pop-up
		this.bsModalRef.hide();
	}

	/**
	 * Fermeture de la recherche successive pour réouvrir la recherche avancée
	 */
	openAdvancedSearch() {
		//Définition du résultat
		this.bsModalRef.content.result = {
			isDismissed: true
		};

		//Fermeture de la pop-up
		this.bsModalRef.hide();
	}

	/**
	 * Retour de la sélection d'éléments
	 */
	validateSelection() {
		//Définition du résultat
		this.bsModalRef.content.result = {
			listeSelectionsItems: this.listeSelectionsItems,
			nbSelectedItems: this.nbSelectedItems,
			isKeepFilteringObject: this.isKeepFilteringObject
		};

		//Fermeture de la pop-up
		this.bsModalRef.hide();
	}

	/**
	 * Récupération de la clef de recherche des sélections
	 */
	private getMapKeyFromCumulatedFilters(groupe?: any): any {
		let mapKey = {};

		//Alimentation de la clef avec les filtres
		this.listeCumulatedFilters.length && this.listeCumulatedFilters.forEach(filter => {
			//Ajout de la clef
			mapKey[filter.clef] = `${filter.valeur}`;
		});

		//Ajout du libellé de groupe dans la clef de recherche
		groupe && (mapKey[this.options.groupeKey] = groupe.libelleGroupe);

		return mapKey;
	}

	/**
	 * Vérification de la visibilité de l'action
	 */
	public isActionVisible(action: Action): boolean {
		//Vérification de la visibilité de l'action
		return action.isVisible?.(this.options,this.listeSelectionsItems);
	}
}