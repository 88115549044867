<form #customizeForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()" class="dashboard-custom">
	<div class="modal-header">
		<h4 class="modal-title" translate>dashboard.custom.title</h4>
	</div>
	<div class="modal-body dashboard-custom-container">
		<!-- Gestion du titre -->
		<div class="section">
			<h4 translate>dashboard.custom.titre</h4>
			<a class="action" [routerLink]="[]" (click)="content.isTitleToggled = !content.isTitleToggled;">
				<icon [name]="!content.isTitleToggled ? 'chevron_right' : 'expand_more'"></icon>
			</a>
		</div>
		<div *ngIf="!content.isTitleToggled">
			<div class="form-group" [ngClass]="{ 'has-error': libelleCustom.invalid }">
				<label translate *ngIf="!dashboardChart.customLibelle?.length">dashboard.custom.titre</label>
				<label translate [translateParams]="{ titreOrigine: dashboardChart.calendrier.libelle }" *ngIf="dashboardChart.customLibelle?.length">dashboard.custom.titrePersonnalise</label>
				<div class="half">
					<input type="text" name="libelleCustom" class="form-control" #libelleCustom="ngModel" [(ngModel)]="dashboardChart.customLibelle" [placeholder]="dashboardChart.calendrier.libelle"/>
				</div>
			</div>
		</div>

		<!-- Gestion des entités -->
		<ng-container *ngIf="dashboardChart.calendrier?.listeDetails">
			<div class="section">
				<h4 translate>dashboard.custom.entities</h4>
				<a class="action" [routerLink]="[]" (click)="content.isEntiteToggled = !content.isEntiteToggled;">
					<icon [name]="!content.isEntiteToggled ? 'chevron_right' : 'expand_more'"></icon>
				</a>
			</div>
			<ng-container *ngIf="!content.isEntiteToggled">
				<div class="text-center" *ngIf="!listeEntites?.length">
					<span translate>dashboard.custom.noEntite</span>
				</div>
				<ng-container *ngFor="let entite of listeEntites; let index = index">
					<div class="form-group" [ngClass]="{ 'has-error': couleur.invalid  }">
						<div class="half section">
							<mat-checkbox #actif="ngModel" [name]="'entiteActif_'+index" [(ngModel)]="entite.actif">{{ entite.libelle }}</mat-checkbox>
							<color-picker #couleur="ngModel" [name]="'entiteCouleur_'+index" [(ngModel)]="entite.couleur" (ngModelChange)="entite.actif = !!$event" [required]="entite.actif"></color-picker>
						</div>
					</div>
				</ng-container>
			</ng-container>
		</ng-container>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveDashboardChart();" [disabled]="customizeForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>